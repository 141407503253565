/*===== Files that compile in main.min.js =======*/

window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');
require('bootstrap');

jQuery(function($) {
  // Provide by client to fix iframe height
  $alfJq = jQuery.noConflict();
  $alfJq(function() {
    try {
      var em = window.addEventListener ? "addEventListener" : "attachEvent";
      window[em](
          em == "attachEvent" ? "onmessage" : "message",
          function(e) {
            try {
              var data = JSON.parse(e.data);
              switch (data.action) {
                case "sizeHeight":
                  $alfJq("iframe#assurland").css({
                    height: data.value
                  });
                  break;
                case "scrollTop":
                  $alfJq("html, body")
                      .stop()
                      .animate(
                          {
                            scrollTop: $alfJq("iframe#assurland").offset().top + data.value
                          },
                          data.value == 0 ? 0 : 1200,
                          null
                      );
                  break;
              }
            } catch (e) {}
          },
          false
      );
    } catch (e) {}
  });
});

document.querySelector('.top-content-btn').onclick = function(e) {
    e.preventDefault();
    window.scrollTo({
        top: document.querySelector('#assurland').offsetTop,
        behavior: 'smooth'
    });
};

// To make 3D image move at right corner
// window.onmousemove = function(e) {
//     var sensibility = 0.1;
//     let wrapper = document.querySelector('.wrapper');
//     var offX = sensibility - e.screenX / wrapper.scrollWidth;
//     var offY = sensibility - e.screenY / window.outerHeight;
//     var offset = 60;
//     var translateProperty = "translate3d(" + getTranslateValue(offX, offset) + "px," + getTranslateValue(offY, offset) + "px, 0px)";

//     if (window.innerWidth > 991) {
//         document.querySelector('.car img').style.transform = translateProperty;
//     }
// };

function getTranslateValue(axisOffset, generalOffset) {
    return Math.round(axisOffset * generalOffset);
}
